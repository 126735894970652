import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { ColorSwatch } from 'site-modules/shared/components/color-swatch/color-swatch';

export function ColorInfo({ color, colorName, label, className, isSrpExpanded, style, colorSide }) {
  return (
    <div className={classnames('size-14', className)} style={style}>
      <ColorSwatch
        color={color}
        displayColorName={false}
        title={`${colorSide} color: ${colorName}`}
        classNames={classnames('rounded', { 'align-text-top': !isSrpExpanded })}
        style={{
          width: '0.75rem',
          height: '0.75rem',
          borderWidth: '1px',
          display: 'inline-block',
          marginRight: '0.25rem',
        }}
      />
      {isSrpExpanded ? <span style={{ verticalAlign: 'text-bottom' }}>{label}</span> : label}
    </div>
  );
}

ColorInfo.propTypes = {
  color: PropTypes.shape({}),
  colorName: PropTypes.string,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  isSrpExpanded: PropTypes.bool,
  style: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
  colorSide: PropTypes.string,
};

ColorInfo.defaultProps = {
  color: null,
  colorName: null,
  className: '',
  isSrpExpanded: false,
  style: {},
  colorSide: '',
};
