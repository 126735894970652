import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Button from 'reactstrap/lib/Button';
import Col from 'reactstrap/lib/Col';
import classnames from 'classnames';
import { get, omit } from 'lodash';
import { useToggle } from 'site-modules/shared/hooks/use-toggle';
import { EventToolbox } from 'client/utils/event-toolbox';
import { INVENTORY_PAGE_CUSTOM_EVENTS } from 'site-modules/shared/constants/inventory/page-events';
import { LeadFormDrawer } from 'site-modules/shared/components/lead-form/lead-form-drawer/lead-form-drawer';

const VALUES_TO_OMIT = [
  'isLink',
  'url',
  'leadForm',
  'firstClickText',
  'firstClickTracking',
  'vinData',
  'modelLinkCode',
  'isPagePrimaryButton',
  'leadFormTrackingParent',
  'showLeasePayments',
  'leadFormDrawerConfig',
];

export function CTAButtonAndForm(props) {
  const {
    text,
    isLink,
    url,
    firstClickText,
    firstClickTracking,
    leadFormTrackingParent,
    leadFormDrawerConfig,
    isPagePrimaryButton,
  } = props;
  const [ctaText, setCtaText] = useState(text);
  const [isCTAClicked, setCTAClicked] = useState(false);
  const [isOpen, toggleOpen] = useToggle(false);

  useEffect(() => {
    if (isPagePrimaryButton) {
      EventToolbox.on(INVENTORY_PAGE_CUSTOM_EVENTS.OPEN_LEAD_FORM, toggleOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleClickCTATextChange(event) {
    if (!isCTAClicked) {
      event.preventDefault();
      setCtaText(firstClickText);
      setCTAClicked(true);
    }
  }

  // data for CTA Button
  // remove params not valid for Button
  const btnProps = omit(props, VALUES_TO_OMIT);

  btnProps[isLink ? 'href' : 'onClick'] = isLink ? url : toggleOpen;

  // Add handler for links that should change text when it is clicked
  if (firstClickText) {
    btnProps.onClick = handleClickCTATextChange;
  }

  // Add different tracking on first click
  const btnPropsUpdated = !isCTAClicked && firstClickTracking ? { ...btnProps, ...firstClickTracking } : btnProps;

  // data for Lead Form
  const { url: leadFormUrl } = props;
  const isLeadFormComponent = !!get(props, 'leadForm');
  const leadFormComponent = get(props, 'leadForm.component');
  const leadFormComponentData = get(props, 'leadForm.data');

  // buttons props
  const {
    type,
    isPrimary,
    isExternalLink,
    isExternalButton,
    columnSizes,
    columnClassName,
    buttonClassName,
    trackingId,
    trackingValue,
    size,
    iconClasses,
    isStickySection,
    isSrp,
    ariaLabel,
    isVdpNonPartnerCallDealer,
    isVdpPartnerListing,
    showCapOneButton,
    isVlaPage,
    showPhoneIcon,
    ...otherBtnProps
  } = btnPropsUpdated;
  const updatedColumnSizes = isVdpNonPartnerCallDealer ? { xs: 5, md: 6, xl: 5 } : columnSizes;

  return (
    <Fragment>
      <Col
        {...updatedColumnSizes}
        className={classnames(
          'cta-column',
          { 'mx-auto': !isStickySection && !isVdpNonPartnerCallDealer && !isVlaPage },
          columnClassName
        )}
      >
        <Button
          size={isSrp ? size : undefined}
          color={classnames({
            success:
              !(
                isExternalLink ||
                isExternalButton ||
                isSrp ||
                isVdpNonPartnerCallDealer ||
                isVdpPartnerListing ||
                isVlaPage
              ) && !showCapOneButton,
            'outline-success': isExternalLink && !showCapOneButton,
            'outline-primary': isExternalButton && !showCapOneButton,
            'blue-50': isSrp,
            'outline-blue-50': (isVdpNonPartnerCallDealer || isVdpPartnerListing) && !showCapOneButton,
            link: showCapOneButton || isVlaPage,
          })}
          className={classnames('cta-button w-100 h-100', buttonClassName, {
            'py-0_5': isVdpNonPartnerCallDealer && !showCapOneButton,
            'px-0_25': !showCapOneButton,
            'text-primary-darker text-transform-none text-left font-weight-bold py-0 px-0 size-16':
              showCapOneButton || isVlaPage,
          })}
          data-test={`contact-dealer-${isPrimary ? 'primary' : 'secondary'}${isStickySection ? '-sticky' : ''}`}
          data-test-type={type}
          data-tracking-id={trackingId}
          data-tracking-value={trackingValue}
          data-hidedelightedoninteraction
          aria-label={ariaLabel ? `${ctaText} ${ariaLabel}` : undefined}
          {...otherBtnProps}
        >
          <span
            className={classnames('cta-text-wrapper h-100 d-inline-flex align-items-center justify-content-center', {
              'font-weight-bold': isVdpNonPartnerCallDealer,
            })}
          >
            {(showCapOneButton || showPhoneIcon) && <i className="icon-phone2 mr-0_5" aria-hidden />}
            {iconClasses && !isVdpNonPartnerCallDealer && <span className={iconClasses} />}
            {ctaText}
            {isExternalLink && <span className="ml-0_25 icon-new-tab" aria-hidden />}
          </span>
        </Button>
      </Col>

      {/* Lead Form */}
      {!isLink && isLeadFormComponent ? (
        <LeadFormDrawer
          url={leadFormUrl}
          component={leadFormComponent}
          componentData={{
            ...leadFormComponentData,
            options: { ...leadFormComponentData.options, isOpen, toggleDrawer: toggleOpen },
          }}
          isOpen={isOpen}
          onDrawerToggle={toggleOpen}
          shouldRemoveOnClose={false}
          dataTrackingParent={leadFormTrackingParent}
          {...leadFormDrawerConfig}
        />
      ) : (
        !isLink && <LeadFormDrawer url={leadFormUrl} isOpen={isOpen} onDrawerToggle={toggleOpen} />
      )}
    </Fragment>
  );
}

CTAButtonAndForm.propTypes = {
  type: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  columnSizes: PropTypes.shape({
    xs: PropTypes.number,
    md: PropTypes.number,
  }).isRequired,
  isPrimary: PropTypes.bool,
  isLink: PropTypes.bool,
  isExternalLink: PropTypes.bool,
  columnClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
  size: PropTypes.string,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  iconClasses: PropTypes.string,
  isStickySection: PropTypes.bool,
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.number,
  showCapOneButton: PropTypes.bool,
  url: PropTypes.string,
  leadForm: PropTypes.shape({
    component: PropTypes.func,
    data: PropTypes.shape({}),
  }),
  isPagePrimaryButton: PropTypes.bool,
  isSrp: PropTypes.bool,
  modelLinkCode: PropTypes.string,
  leadFormTrackingParent: PropTypes.string,
  showLeasePayments: PropTypes.bool,
  isVdpNonPartnerCallDealer: PropTypes.bool,
  isVdpPartnerListing: PropTypes.bool,
  isVlaPage: PropTypes.bool,
  showPhoneIcon: PropTypes.bool,
  leadFormDrawerConfig: PropTypes.shape({}),
};

CTAButtonAndForm.defaultProps = {
  isPrimary: false,
  isExternalLink: false,
  isLink: false,
  columnClassName: '',
  size: 'responsive',
  buttonClassName: '',
  trackingId: null,
  trackingValue: null,
  iconClasses: '',
  isStickySection: false,
  ariaLabel: null,
  tabIndex: 0,
  showCapOneButton: false,
  url: '',
  leadForm: null,
  isPagePrimaryButton: false,
  isSrp: false,
  modelLinkCode: '',
  leadFormTrackingParent: undefined,
  showLeasePayments: false,
  isVdpNonPartnerCallDealer: false,
  isVdpPartnerListing: false,
  isVlaPage: false,
  showPhoneIcon: false,
  leadFormDrawerConfig: {},
};
