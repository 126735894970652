import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Row from 'reactstrap/lib/Row';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { connectToModel, bindToPath } from 'client/data/luckdragon/redux/react-binding';
import { PageModel } from 'client/data/models/page';
import { VisitorModel } from 'client/data/models/visitor';
import { InventoryEntities } from 'client/data/models/inventory';
import { DealerInfoEntities } from 'client/data/models/dealer-info';
import { CTA_TYPE } from 'site-modules/shared/constants/inventory/lead-form';

import { getLeadFormCTAs, getCTAToDisplay } from 'site-modules/shared/components/inventory/utils/lead-form-ctas';
import { getLeadFormCreativeId } from 'site-modules/shared/components/lead-form/utils/lead-form-creative-id';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { isPartnerDealer } from 'site-modules/shared/utils/dealer-details-utils';
import { LEAD_FORMS_CREATIVE_ID } from 'site-modules/shared/components/lead-form/unified-lead-form/constants/creative-ids';

import { CTAButtonAndForm } from './contact-dealer-cta';

export function CTAButtonAndFormRenderUI({
  tabIndex,
  vinData,
  pageName,
  zipCode,
  isMobile,
  isStickySection,
  rootClassName,
  isSrp,
  isNational,
  ctasOptions,
  buttonClassName,
  buttonSize,
  skipCBPButton,
  reverseOrder,
  onlyOnePrimary,
  buttonSecondarySize,
  isPagePrimaryButton,
  isTopEmbeddedLeadFormEnabled,
  pageNameLegacy,
  isSimilarCards,
  isVdpPartnerListing,
  isVdpNonPartner,
  ariaLabel,
  isDealerSrp,
  leadFormDrawerConfig,
  showCapOneButton,
  isVlaPage,
}) {
  const daysToSell = get(vinData, 'computedDisplayInfo.estimatedDaysToSell');
  const isPartnerListing = isPartnerDealer(vinData);
  const isNewVin = isNew(vinData.type);
  const bestDealPrice = get(
    vinData,
    'computedDisplayInfo.transparentPricingCompliance.bestDealPrice',
    get(vinData, 'computedInfo.bestDealPrice', 0)
  );

  const ctasOptionsUpdated = {
    ...ctasOptions,
    isPartnerListing,
    isSrp,
    isMobile,
    skipDRBtn: skipCBPButton || ctasOptions.skipDRBtn,
    pageNameLegacy,
    daysToSell,
    bestDealPrice,
    preSelectedCardNumbers: isSimilarCards ? 1 : undefined,
    isVdpPartnerListing,
    isDealerSrp,
  };

  const displayOptions = { buttonSize, reverseOrder, onlyOnePrimary, buttonSecondarySize };
  const ctas = getLeadFormCTAs(vinData, pageName, zipCode, ctasOptionsUpdated, isNational);

  const embeddedLeadFormOptions = { isTopEmbeddedLeadFormEnabled, skipCBPButton };
  const ctasToDisplay = getCTAToDisplay(ctas, isMobile, isSrp, vinData, displayOptions, embeddedLeadFormOptions);
  const leadFormTrackingParent = getLeadFormCreativeId({
    isNewVin,
    pageNameLegacy,
    formName: LEAD_FORMS_CREATIVE_ID.SINGLE_BASE_VDP_FORM,
  });

  return (
    <>
      {!!ctasToDisplay.length && (
        <Row
          className={classNames('contact-dealer', rootClassName, {
            'justify-content-end flex-grow-2': isStickySection,
          })}
        >
          {ctasToDisplay.map(ctaInfo => (
            <CTAButtonAndForm
              key={ctaInfo.type}
              buttonClassName={buttonClassName}
              {...ctaInfo}
              isStickySection={isStickySection}
              isSrp={isSrp}
              vinData={vinData}
              isPagePrimaryButton={isPagePrimaryButton}
              showLeasePayments={ctasOptions.showLeasePayments}
              leadFormTrackingParent={ctaInfo.leadFormTrackingParent || leadFormTrackingParent}
              isVdpNonPartnerCallDealer={isVdpNonPartner && ctaInfo.type === CTA_TYPE.CALL_DEALER}
              ariaLabel={ariaLabel}
              tabIndex={tabIndex}
              isVdpPartnerListing={isVdpPartnerListing}
              leadFormDrawerConfig={leadFormDrawerConfig}
              showCapOneButton={showCapOneButton}
              isVlaPage={isVlaPage}
              showPhoneIcon={isVlaPage && !isMobile}
            />
          ))}
        </Row>
      )}
    </>
  );
}

CTAButtonAndFormRenderUI.propTypes = {
  pageName: PropTypes.string.isRequired,
  zipCode: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  isStickySection: PropTypes.bool,
  vinData: InventoryEntities.InventoryVin,
  isSrp: PropTypes.bool,
  rootClassName: PropTypes.string,
  ctasOptions: PropTypes.shape({
    featureFlags: PropTypes.shape({}),
    pathname: PropTypes.string.isRequired,
    dealerInfo: DealerInfoEntities.DealerInfo,
    radius: PropTypes.number,
    skipDRBtn: PropTypes.bool,
  }),
  isNational: PropTypes.bool,
  buttonClassName: PropTypes.string,
  buttonSize: PropTypes.string,
  skipCBPButton: PropTypes.bool,
  reverseOrder: PropTypes.bool,
  onlyOnePrimary: PropTypes.bool,
  buttonSecondarySize: PropTypes.string,
  isPagePrimaryButton: PropTypes.bool,
  isTopEmbeddedLeadFormEnabled: PropTypes.bool,
  pageNameLegacy: PropTypes.string,
  isSimilarCards: PropTypes.bool,
  isVdpPartnerListing: PropTypes.bool,
  isVdpNonPartner: PropTypes.bool,
  ariaLabel: PropTypes.string,
  tabIndex: PropTypes.number,
  isDealerSrp: PropTypes.bool,
  leadFormDrawerConfig: PropTypes.shape({}),
  showCapOneButton: PropTypes.bool,
  isVlaPage: PropTypes.bool,
};

CTAButtonAndFormRenderUI.defaultProps = {
  isMobile: false,
  isStickySection: false,
  vinData: {},
  isSrp: false,
  rootClassName: '',
  isNational: false,
  ctasOptions: {},
  buttonClassName: '',
  buttonSize: '',
  skipCBPButton: false,
  reverseOrder: false,
  onlyOnePrimary: false,
  buttonSecondarySize: '',
  isPagePrimaryButton: false,
  isTopEmbeddedLeadFormEnabled: false,
  pageNameLegacy: '',
  isSimilarCards: false,
  isVdpPartnerListing: false,
  isVdpNonPartner: false,
  ariaLabel: null,
  tabIndex: 0,
  isDealerSrp: false,
  leadFormDrawerConfig: {},
  showCapOneButton: false,
  isVlaPage: false,
};

export const mapStateToProps = state => ({
  pageName: state.pageContext.page.name,
  zipCode: state.visitor.location.zipCode,
  skipCBPButton: state.featureFlags.skipCBPButton,
  pageNameLegacy: get(state, 'pageContext.legacy.pageName') || get(state, 'pageContext.page.name'),
});

export const stateToPropsConfig = {
  zipCode: bindToPath('location.zipCode', VisitorModel),
  pageName: bindToPath('page.name', PageModel),
};

export const CTAButtonAndFormRender = connect(mapStateToProps)(
  connectToModel(CTAButtonAndFormRenderUI, stateToPropsConfig)
);
