import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get } from 'lodash';
import { isNew } from 'site-modules/shared/utils/inventory-utils/is-new';
import { InventoryEntities } from 'client/data/models/inventory';
import { numberWithCommas } from 'site-modules/shared/utils/string';
import { formatPriceString } from 'site-modules/shared/utils/price-utils';
import { isDealerPriceAvailable } from 'site-modules/shared/utils/inventory-utils/is-dealer-price-available';
import {
  DEAL_TYPES,
  DEALS_BG_COLORS,
  DEALS_BG_COLORS_STYLE,
  DEFAULT_PRICE,
  DEAL_TYPE_ICON,
  TEXT_COLOR,
} from 'site-modules/shared/components/deal/deal-constants';
import { DATA_PATH } from 'client/engagement-handlers/inventory-engagement-handler/constants';

import './pricing-details.scss';

export function PricingDetails({
  vehicle,
  isSmallCurrencyLabel,
  displayBelowMarketCopy,
  belowMarketCopyClassName,
  displayLeaseMonthlyPayment,
  displayLoanMonthlyPayment,
  displayDownPayment,
  displayDealBadge,
  displayMSRPCopyRight,
  displayMSRPCopyLeft,
  classCurrencyLabel,
  priceClasses,
  noPriceClasses,
  msrpCopyClasses,
  withoutRatingBubble,
}) {
  const { type, prices } = vehicle;
  const displayPrice = get(prices, 'displayPrice', 0);
  const savings = get(vehicle, 'computedDisplayInfo.priceValidation.priceDiscount', 0);
  const dealType = get(vehicle, 'computedDisplayInfo.priceValidation.dealType', '');
  const leasePayment = get(prices, 'estimateLeasePromise.monthlyPayment', 0);
  const downPayment = get(prices, 'estimateLeasePromise.downPayment', 0);
  const loanPayment = get(prices, 'loan.payment', 0);
  const dealerPrice = get(vehicle, DATA_PATH.DEALER_PRICE);
  const showDealerPrice = isDealerPriceAvailable(vehicle) && !displayLeaseMonthlyPayment && !displayLoanMonthlyPayment;
  const title = showDealerPrice ? 'Dealer Price' : 'MSRP';
  let priceToShow = showDealerPrice ? dealerPrice : displayPrice;

  if (displayLeaseMonthlyPayment) {
    priceToShow = leasePayment;
  }
  if (displayLoanMonthlyPayment) {
    priceToShow = loanPayment;
  }

  const dealTagStyle = {
    ...(withoutRatingBubble ? {} : { backgroundColor: DEALS_BG_COLORS_STYLE[dealType], color: '#fff' }),
    whiteSpace: 'nowrap',
    fontSize: '0.75rem',
    letterSpacing: 0,
    lineHeight: '1rem',
    borderRadius: '0.25rem',
    padding: '4px 4px',
    marginLeft: '8px',
  };

  const hasDueAtSigning = displayDownPayment && !!downPayment;

  return (
    <div className="pricing-details d-flex align-items-center">
      {priceToShow ? (
        <Fragment>
          <span>
            {displayMSRPCopyLeft && <span className={classnames('mr-0_5', msrpCopyClasses)}>{title}</span>}
            {isSmallCurrencyLabel && (
              <sup className={classnames('size-14 dollar-symbol text-gray-darkest', classCurrencyLabel)}>$</sup>
            )}
            <span className={classnames('display-price font-weight-bold text-gray-darkest', priceClasses)}>
              {isSmallCurrencyLabel ? numberWithCommas(priceToShow) : formatPriceString(priceToShow)}
            </span>
            {displayLeaseMonthlyPayment && (
              <span className="size-14">
                /month est.
                {hasDueAtSigning && `, ${formatPriceString(downPayment)} down payment`}
              </span>
            )}
            {displayLoanMonthlyPayment && (
              <span className="size-14">
                /month est.
                {`, ${formatPriceString(displayPrice)} ${isNew(type) ? 'MSRP' : 'Price'}`}
              </span>
            )}
          </span>
          {displayDealBadge && DEAL_TYPES.includes(dealType) && (
            <span
              className={classnames('deal-tag ml-0_5 p-0_25 size-12 rounded d-flex align-items-center', {
                [DEALS_BG_COLORS[dealType]]: !withoutRatingBubble,
                'text-white': !withoutRatingBubble,
              })}
              style={dealTagStyle}
            >
              <i
                className={classnames('align-middle mr-0_75', DEAL_TYPE_ICON[dealType], {
                  [TEXT_COLOR[dealType]]: withoutRatingBubble,
                })}
              />
              <span
                className={classnames('text-lowercase', {
                  'font-weight-bold size-14': withoutRatingBubble,
                })}
              >
                {dealType} price
              </span>
              {displayBelowMarketCopy && (
                <span className={classnames(belowMarketCopyClassName)} style={{ marginLeft: '4px' }}>
                  | {formatPriceString(savings)} below market
                </span>
              )}
            </span>
          )}
          {displayMSRPCopyRight && (
            <span className={classnames('size-14 ml-0_25 mt-0_25', msrpCopyClasses)}>{title}</span>
          )}
        </Fragment>
      ) : (
        <span className={classnames('display-price', noPriceClasses)}>{DEFAULT_PRICE}</span>
      )}
    </div>
  );
}

PricingDetails.propTypes = {
  vehicle: InventoryEntities.InventoryVin.isRequired,
  isSmallCurrencyLabel: PropTypes.bool,
  displayBelowMarketCopy: PropTypes.bool,
  belowMarketCopyClassName: PropTypes.string,
  displayLeaseMonthlyPayment: PropTypes.bool,
  displayLoanMonthlyPayment: PropTypes.bool,
  displayDownPayment: PropTypes.bool,
  displayDealBadge: PropTypes.bool,
  displayMSRPCopyRight: PropTypes.bool,
  displayMSRPCopyLeft: PropTypes.bool,
  classCurrencyLabel: PropTypes.string,
  priceClasses: PropTypes.string,
  noPriceClasses: PropTypes.string,
  msrpCopyClasses: PropTypes.string,
  withoutRatingBubble: PropTypes.bool,
};

PricingDetails.defaultProps = {
  isSmallCurrencyLabel: false,
  displayBelowMarketCopy: false,
  belowMarketCopyClassName: null,
  displayLeaseMonthlyPayment: false,
  displayLoanMonthlyPayment: false,
  displayDownPayment: false,
  displayDealBadge: true,
  displayMSRPCopyRight: false,
  displayMSRPCopyLeft: false,
  classCurrencyLabel: undefined,
  priceClasses: '',
  noPriceClasses: 'font-weight-bold',
  msrpCopyClasses: '',
  withoutRatingBubble: false,
};
